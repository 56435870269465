import {Drawer, Menu} from 'antd';
import { useState } from 'react';
import {HomeOutlined, InfoCircleOutlined, LaptopOutlined, MenuOutlined, TeamOutlined} from '@ant-design/icons';
import './NavBar.css'
import logo from './logo vtt.png'
import {useNavigate} from "react-router-dom";

const items = [
    {
        label: 'Home',
        key: 'home',
        icon: <HomeOutlined/>,
        route: '',
        style: { justifyContent: 'center' }
    },
    {
        label: 'Senioren',
        key: 'senioren',
        icon: <TeamOutlined />,
        route: ''
    },
    {
        label: 'Jeugd',
        key: 'jeugd',
        icon: <TeamOutlined />,
        route: ''
    },
    {
        label: 'Clubinfo',
        key: 'clubinfo',
        icon: <InfoCircleOutlined />,
        route: ''
    },
    {
        label: 'Sponsors',
        key: 'sponsors',
        icon: <LaptopOutlined />,
        route: ''
    },
]

export const NavBar = () => {

    const [openMenu, setOpenMenu] = useState(false);


    return (
        <div className='horizontal-div-navbar'>
            <div className='mobile'>
                <MenuOutlined className='navbar-drawer' onClick={() => {setOpenMenu(true)}}/>
            </div>
            <div className='desktop horizontal-div-navbar'>
                <img src={logo} alt="Logo VTT" className='logo'/>
                <AppMenu />
            </div>

            <Drawer
                placement='left'
                open={openMenu}
                onClose={() => {setOpenMenu(false);}}
                closable={false}
                bodyStyle={{backgroundColor: 'black' }}>

                <AppMenu isInline={true} openMenu={openMenu} setOpenMenu={setOpenMenu}/>

            </Drawer>
        </div>
    )
}

function AppMenu({ isInline= false, openMenu=null, setOpenMenu=null }) {

    const [current, setCurrent] = useState('home')
    const navigate = useNavigate();

    const onClick = (e) => {
        setCurrent(e.key);
        if(e.key === "home") {
            navigate("/");
        } else {
            navigate(e.key);
        }
        if (openMenu !== null) {
            setOpenMenu(false);
        }
    };

    return (
        <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode={ isInline ? 'inline' : 'horizontal' }
            items={items}
            size='50px'
            className='navbar'/>
    )
}
