import {getTeams} from "../API/API";
import {useEffect, useState} from "react";
import {Card} from "antd";
import Meta from "antd/es/card/Meta";
import "./Teams.css";
import {useNavigate} from "react-router-dom";
import Fade from 'react-reveal/Fade';

export const TeamOVerview = () => {

    const [teams, setTeams] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        async function fetchData() {
            setTeams(await getTeams());
        }
        fetchData();
    }, []);

    function clickTeam(team) {
        navigate(team.id.toString());
    }



    return (
        <div className='team-overview-div'>
            {
                teams.map((team) => (
                    <Fade left>
                        <div key={team.id} className="team-card-div">
                            <Card hoverable style={{ width: 500, justifyContent: "center" }} cover={<img alt='NO IMAGE AVAILABLE' src={team.image} />} onClick={() => clickTeam(team)}>
                                <Meta title={team.name + " " +team.gender} description={team.league}/>
                            </Card>
                        </div>

                    </Fade>

                ))
            }
        </div>
    )
}