import {getYouthTeams} from "../API/API";
import {useEffect, useState} from "react";
import {Card} from "antd";
import Meta from "antd/es/card/Meta";
import "../teams/Teams.css";
import "./YouthTeams.css"
import {useNavigate} from "react-router-dom";
import Fade from 'react-reveal/Fade';

export const YouthTeamOverview = () => {

    const [teams, setTeams] = useState([]);
    const navigate = useNavigate();


    useEffect(() => {
        async function fetchData() {
            setTeams(await getYouthTeams());
        }
        fetchData();
    }, []);

    function clickTeam(team) {
        navigate(team.id.toString());
    }



    return (
        <div className="youth-wrapper">
            <div className='youth-team-overview-div'>
            {
                teams.map((team) => (
                    <Fade left>
                        <div key={team.id} className="youth-team-card-div">
                            <Card hoverable style={{ justifyContent: "center" }} cover={<img alt='NO IMAGE AVAILABLE' src={team.image} />} onClick={() => clickTeam(team)}>
                                <Meta title={team.name + " " +team.gender} description={team.league}/>
                            </Card>
                        </div>

                    </Fade>

                ))
            }
            </div>
        </div>

    )
}