// const APIUrl = '/api';
// const APIUrl = 'http://localhost:8000/api';
const APIUrl = process.env.REACT_APP_APIURL;
const TeamAPI = APIUrl+'/teams/';
const PlayerAPI = APIUrl+'/players/'
const SponsorAPI = APIUrl+'/sponsors/'
const BoardAPI = APIUrl+'/board/'
const YouthTeamAPI = APIUrl+'/youth_teams/'
const YouthPlayerAPI = APIUrl+'/youth_players/'


export const getTeams = async () => {
    try {
        const response = await fetch(TeamAPI);
        const data = await response.json();
        return data.results;
    } catch (error) {
        console.error(error);
    }
}

export const getYouthTeams = async () => {
    try {
        const response = await fetch(YouthTeamAPI);
        const data = await response.json();
        return data.results;
    } catch (error) {
        console.error(error);
    }
}

export const getSponsors = async () => {
    try {
        const response = await fetch(SponsorAPI);
        const data = await response.json();
        return data.results;
    } catch (error) {
        console.error(error);
    }
}

export const getBoard = async () => {
    try {
        const response = await fetch(BoardAPI);
        const data = await response.json();
        return data.results;
    } catch (error) {
        console.error(error);
    }
}

export const getTeamById = async (id) => {
    try {
        const response = await fetch(TeamAPI+id+'/');
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const getYouthTeamById = async (id) => {
    try {
        const response = await fetch(YouthTeamAPI+id+'/');
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
    }
}

export const getYouthPlayersById = async (id) => {
    try {
        const response = await fetch(YouthPlayerAPI);
        const data = await response.json();

        return data.results.filter(player => player.youth_team.toString().charAt(player.youth_team.length-2) === id);
    } catch (error) {
        console.log(error)
    }
}

export const getPlayersById = async (id) => {
    try {
        const response = await fetch(PlayerAPI);
        const data = await response.json();

        return data.results.filter(player => player.team.toString().charAt(player.team.length-2) === id);
    } catch (error) {
        console.log(error)
    }
}
