import React from 'react';
import { Layout } from 'antd';

import './Footer.css'
import { FacebookOutlined, InstagramOutlined } from '@ant-design/icons';

const { Footer } = Layout;

const BasicFooter = () => {
  return (
    <Footer style={{ textAlign: 'center' }} className='vtt_footer'>
        <h1>
            Volley Team Temse
        </h1>
        <div className='half-div-wrap'>
            <div className='half-div'>
                <div>
                    <p style={{ fontWeight: 'bold' }}>Sporthal Het Gelaag</p>
                    <p style={{ fontWeight: 'bold' }}>sparrenlaan 25</p>
                    <p style={{ fontWeight: 'bold' }}>9140 Steendorp</p>
                    <p style={{ fontWeight: 'bold' }}>0475/87.27.45</p>
                </div>
            </div>
            <div className='half-div'>
                <div>
                    <a href='https://www.facebook.com/vttemse.be/?locale=nl_BE'>
                        <FacebookOutlined style={{ fontSize: '50px', paddingLeft: '10px', paddingRight: '10px' }} />
                    </a>
                    <a href='https://www.instagram.com/vttemse/'>
                        <InstagramOutlined style={{ fontSize: '50px', paddingLeft: '10px', paddingRight: '10px'  }} />
                    </a>
                </div>
            </div>
        </div>
    </Footer>
  );
};

export default BasicFooter;
