import {PersonCard} from "./PersonCard";
import './Clubinfo.css'
import {useEffect, useState} from "react";
import {getBoard} from "../API/API";

export const InfoPage = () => {

    const [board, setBoard] = useState([]);


    useEffect(() => {
        async function fetchData() {
            setBoard(await getBoard());
        };
        fetchData();
    }, []);



    return (
        <div className='person-div-wrap'>
            <div className='person-div'>
                <h1>Clubinfo</h1>
                <h2 className='heading'>Bestuursleden</h2>
                <div className='parent-div'>
                    <div className='board-div'>
                        {
                            board.map((person) => (
                                <PersonCard key={person.url} name={person.name} description={person.function} email={person.email} image={person.image} phone={person.phone_number} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}