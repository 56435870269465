import "../css/General.css"
import "./FrontPage.css"
import "./GameCard.css"
import {GameCard} from "./GameCard";
import {useEffect, useState} from "react";
import {getTeams} from "../API/API";
import Fade from 'react-reveal/Fade';
import Verzekering from "./Verzekering";
import frontpage from './images/VTTemse_frontpage_final.png';
import trooper from './images/trooper.png';
import jeugdfonds from './images/Jeugdsportfonds.png';
import ehbo from './images/ehbo.png';
import api from './images/api.png';
import { Button, Tooltip } from 'antd';


export const Frontpage = () => {

    const [teams, setTeams] = useState([]);

    useEffect(() => {
        async function fetchData() {
            setTeams(await getTeams());
        }
        fetchData();
    }, []);

    return (
        <>
            <Fade top>
                <div className="picture-div-wrapper bottom-padding">
                    <div className="picture-div">
                        <img src={frontpage} alt="frontpage picture" className="picture"></img>
                    </div>
                </div>
            </Fade>

            <Fade top>
                <h1>Komende wedstrijden</h1>
                <div className="parent-div bottom-padding">
                        <div className="game-card-div child-div">
                            {
                                teams.map((team) => (
                                    <div >
                                        <GameCard key={team.id} id={team.id} gender={team.gender} name={team.name}>
                                        </GameCard>
                                    </div>
                                ))
                            }
                        </div>
                    
                </div>
            </Fade>
            <h1>Algemene info</h1>
            <div className="picture-div-wrapper">
                <a className="clickable-link" href="/aanspreekpunt_integriteit">
                    <Tooltip title='Aanspreekpunt integriteit'>
                        <img src={api} alt="api" className="trooper"></img>
                    </Tooltip>
                </a>
                <a className="clickable-link" href="https://www.trooper.be/nl/trooperverenigingen/vtt">
                    <Tooltip title='Steun VTTemse via trooper!'>
                        <img src={trooper} alt="trooper" className="trooper"></img>
                    </Tooltip>
                </a>
                <a className="clickable-link" href="https://volleyvlaanderen.be/projecten/jeugdsportfonds">
                    <Tooltip title='VT Temse deelt jaarlijks neem aan het project jeugdsportfonds van Volley Vlaanderen om de jeugdwerking van de club te optimaliseren!'>
                        <img src={jeugdfonds} alt="jeugdfonds" className="trooper"></img>
                    </Tooltip>
                </a>
                <a className="clickable-link" href="sportongeval">
                    <Tooltip title="Een sportongeval gehad bij onze club? Klik voor meer informatie over de verzekering.">
                        <img src={ehbo} alt="verzekering" className="trooper"></img>
                    </Tooltip>
                </a>
            </div>
        </>

    )
}