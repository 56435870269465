import {getPlayersById, getTeamById} from "../API/API";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Image, Table} from "antd";
import "./Teams.css"
import "../css/General.css"
import { useMediaQuery } from 'usehooks-ts'


export const TeamDetail = () => {

    const birth_date = useMediaQuery('(min-width: 440px)');
    const number = useMediaQuery('(min-width: 650px)');

    const navigate = useNavigate();

    const practice_columns = [
        {
            title: 'Dag',
            dataIndex: 'day',
            key: 'day'
        },
        {
            title: 'Start',
            dataIndex: 'start',
            key: 'start'
        },
        {
            title: 'Eind',
            dataIndex: 'end',
            key: 'end'
        },
        {
            title: 'Locatie',
            dataIndex: 'location',
            key: 'location'
        }
    ];

    const columns = [
        {
            title: 'Naam',
            dataIndex: 'name',
            key: 'name',
            width: 1,
        },
        {
            title: number ? 'Nummer' : 'Nr',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: birth_date ? 'Geboortedatum' : 'Geboorte- datum',
            dataIndex: 'birth_date',
            key: 'birth_date',
        },
        {
            title: 'Positie',
            dataIndex: 'position',
            key: 'position',
        }
    ]

    const match_columns = [
        {
            title: 'Datum',
            dataIndex: 'Datum',
            key: 'Datum'
        },
        {
            title: 'Aanvangsuur',
            dataIndex: 'aanvangsuur',
            key: 'aanvangsuur'
        },
        {
            title: 'Thuisploeg',
            dataIndex: 'thuisploeg',
            key: 'thuisploeg'
        },
        {
            title: 'Resulthoofd',
            dataIndex: 'resulthoofd',
            key: 'resulthoofd'
        },
        {
            title: 'Bezoekers',
            dataIndex: 'bezoekers',
            key: 'bezoekers'
        }
    ]


    const { id } = useParams();

    function clickResults() {
        navigate('resultaten')
    }

    const [players, setPlayers] = useState([]);
    const [team, setTeam] = useState({});
    const [matches, setMatches] = useState([]);
    const [practices, setPractices] = useState([]);
    const [conactPerson, setContactPerson] = useState({});

    useEffect(() => {
        async function fetchData() {
            const playerData = await getPlayersById(id);
            const teamData = await getTeamById(id);
            // console.log(teamData);
            setPlayers(playerData);
            console.log(teamData);
            setTeam(teamData);
            setMatches(teamData.matches);
            setPractices(teamData.practices);
        };
        fetchData();
    }, [id]);

    const playersWithKeys = players.map((player) => ({
        ...player,
        key: player.url, // Assuming player.id is a unique identifier for each player
    }));

    const matchesWithKeys = matches.map((match) => ({
        ...match,
        key: match.datum+match.thuisploeg+match.bezoekers,
    }));

    const practicesWithKeys = practices.map((practice) => ({
        ...practice,
        key: practice.url,
    }))

    return (
        <div className='page-wrap'>
            <h1>{team.gender} {team.name}</h1>
            <h2 className='heading'>Spelerslijst</h2>
            <div className='half-div-wrap'>
                <div className='half-div'>
                    <Table pagination={{position: ['bottomCenter']}} columns={columns} dataSource={playersWithKeys} className='table'/>
                </div>
                <div className='half-div-debug'>
                    <Image preview={false} src={team.image} className='team-image'></Image>

                </div>
            </div>
            <div className='contact-training-div half-div-wrap'>

                <div className='half-div'>
                    <div className='training-div'>
                        <h2 className='heading'>Trainingen</h2>
                        <Table pagination={{position: ['none']}} columns={practice_columns} dataSource={practicesWithKeys}/>
                    </div>
                </div>
            </div>
            <h2 className='heading'>Uitslagen</h2>
            <div className='player-div'>
                <Table columns={match_columns} dataSource={matchesWithKeys} className='table'/>
            </div>
        </div>
    )
}