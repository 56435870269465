import './App.css';
import {NavBar} from "./navbar/NavBar";
import {Frontpage} from "./frontpage/Frontpage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {TeamOVerview} from "./teams/TeamOVerview";
import {TeamDetail} from "./teams/TeamDetail";
import {SponsorPage} from "./sponsor/SponsorPage";
import {InfoPage} from "./clubinfo/InfoPage";
import {YouthTeamOverview} from "./youthteams/YouthTeamOverview"
import {YouthTeamDetail} from './youthteams/YouthTeamDetail';
import {TeamResultOverView} from "./teams/results/TeamResultOverView";
import Insurance from './Insurance/InsurancePage';
import BasicFooter from './footer/Footer';
import { Aanspreekpunt } from './aanspreekpunt/aanspreekpunt';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <NavBar/>
            <Routes>
                <Route path="/" element={<Frontpage/>}/>
                <Route path="/senioren" element={<TeamOVerview />}/>
                <Route path="/senioren/:id" element={<TeamDetail />}/>
                <Route path="/jeugd" element={<YouthTeamOverview />}/>
                <Route path="/jeugd/:id" element={<YouthTeamDetail />}/>
                <Route path="/clubinfo" element={<InfoPage />}/>
                <Route path="/sponsors" element={<SponsorPage />}/>
                <Route path="/sportongeval" element={<Insurance />}/>
                <Route path="aanspreekpunt_integriteit" element={<Aanspreekpunt/>}/>
            </Routes>
        </BrowserRouter>
        <BasicFooter/>
    </div>
  );
}

export default App;
